<mat-sidenav-container class="sidebar" [ngClass]="(expanded$ | async) ? 'sidebar--open' : 'sidebar--close'">

    <mat-sidenav #sidenav mode="side" opened="true" class="sidebar__sidenav" *ngIf="(showNav$ | async)">
        <a class="sidebar__logo" [routerLink]="'/'">
            <img src="/assets/img/archaic-quest.png" alt="ArchaicQuest II" />
        </a>

        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header matTooltip="Dashboard" matTooltipPosition="right"
                    (click)="toggleSidenav(true)">
                    <i class="fa fa-home" aria-hidden="true"></i>
                    <span *ngIf="expanded$ | async">Home</span>
                </mat-expansion-panel-header>
                <ul>
                    <li>
                        <a (click)="toggleSidenav(false)" [routerLink]="['/']">
                            <i class="fa fa-tachometer" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">Dashboard</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="toggleSidenav(false)" [routerLink]="['/settings']">
                            <i class="fa fa-cog" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">Settings</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="toggleSidenav(false)" [routerLink]="['/account/manage']">
                            <i class="fa fa-user" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">Users</span>
                        </a>
                    </li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header matTooltip="Areas" matTooltipPosition="right" (click)="toggleSidenav(true)">
                    <i class="fa fa-map-signs" aria-hidden="true"></i>
                    <span *ngIf="expanded$ | async">Areas</span>
                </mat-expansion-panel-header>
                <ul>
                    <li>
                        <a [routerLink]="['/world/list-areas']" (click)="toggleSidenav(false)">
                            <i class="fa fa-search" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">View Areas</span>
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/world/add-area']" (click)="toggleSidenav(false)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">Create Area</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="clearRoomCache()">
                            <i class="fa fa-database" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">Clear Cache</span>
                        </a>

                    </li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header matTooltip="Items" matTooltipPosition="right" (click)="toggleSidenav(true)">
                    <i class="fa fa-shield" aria-hidden="true"></i>
                    <span *ngIf="expanded$ | async">Items</span>
                </mat-expansion-panel-header>
                <ul>
                    <li>
                        <a [routerLink]="['/items']" (click)="toggleSidenav(false)">
                            <i class="fa fa-search" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">View Items</span>
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/add-item']" (click)="toggleSidenav(false)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">Create Item</span>
                        </a>
                    </li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header matTooltip="Crafting Recipes" matTooltipPosition="right"
                    (click)="toggleSidenav(true)">
                    <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="drafting-compass" role="img"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fa fa-crafting">
                        <g class="fa-group">
                            <path fill="currentColor"
                                d="M12.5 249.86c-4.86-7.67-1.89-18 6.05-22.39l28.07-15.57c7.48-4.15 16.61-1.46 21.26 5.72a223.89 223.89 0 0 0 35.75 42.55L71 316.68a288.08 288.08 0 0 1-58.5-66.82zm487 0c4.86-7.67 1.89-18-6.05-22.39l-28.07-15.57c-7.48-4.15-16.61-1.46-21.26 5.72a223.95 223.95 0 0 1-257.77 91.26l-32.53 56.35A287.42 287.42 0 0 0 256 384a288.42 288.42 0 0 0 243.5-134.14z"
                                class="fa-secondary"></path>
                            <path fill="currentColor"
                                d="M457.21 344.75a318.63 318.63 0 0 1-82.85 48.51l54.5 94.4 53.95 23A16 16 0 0 0 505 497.9l7-58.25zM340.9 143.3a96.5 96.5 0 1 0-169.29-.88L0 439.65l7 58.25a16 16 0 0 0 22.17 12.8l54-23 170.11-294.76c1.07 0 2.14.06 3.22.06h2.24l50.49 87.47a191.71 191.71 0 0 0 82.65-48.85zM256.5 128A31.5 31.5 0 1 1 288 96.5a31.5 31.5 0 0 1-31.5 31.5z"
                                class="fa-primary"></path>
                        </g>
                    </svg>
                    <span *ngIf="expanded$ | async">Crafting Recipes</span>
                </mat-expansion-panel-header>
                <ul>
                    <li>
                        <a [routerLink]="['/crafting-recipes/view']" (click)="toggleSidenav(false)">
                            <i class="fa fa-search" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">View Crafting Recipes</span>
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/crafting-recipes/add']" (click)="toggleSidenav(false)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">Create Crafting Recipes</span>
                        </a>
                    </li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header matTooltip="Mobs" matTooltipPosition="right" (click)="toggleSidenav(true)">
                    <i class="ra ra-fox " aria-hidden="true"></i>
                    <span *ngIf="expanded$ | async">Mobs</span>
                </mat-expansion-panel-header>
                <ul>
                    <li>
                        <a [routerLink]="['/mobs']" (click)="toggleSidenav(false)">
                            <i class="fa fa-search" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">View Mobs</span>
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/add-mob']" (click)="toggleSidenav(false)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">Create Mob</span>
                        </a>
                    </li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header matTooltip="View Players" matTooltipPosition="right"
                    (click)="toggleSidenav(true)">
                    <i class="ra ra-player " aria-hidden="true"></i>
                    <span *ngIf="expanded$ | async">Players</span>
                </mat-expansion-panel-header>
                <ul>
                    <li>
                        <a [routerLink]="['/player/accounts/']" (click)="toggleSidenav(false)">
                            <i class="fa fa-search" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">View Accounts</span>
                        </a>
                    </li>

                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header matTooltip="Skills & Spells" matTooltipPosition="right"
                    (click)="toggleSidenav(true)">
                    <i class="ra ra-flame-symbol" aria-hidden="true"></i>
                    <span *ngIf="expanded$ | async">Skills & Spells (WIP)</span>
                </mat-expansion-panel-header>
                <ul>
                    <li>
                        <a [routerLink]="['/skills-spells/view']" (click)="toggleSidenav(false)">
                            <i class="fa fa-search" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">View skills & spells</span>
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/skills-spells/add']" (click)="toggleSidenav(false)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">Create skills & spells</span>
                        </a>
                    </li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header matTooltip="Class" matTooltipPosition="right" (click)="toggleSidenav(true)">
                    <i class="ra ra-axe" aria-hidden="true"></i>
                    <span *ngIf="expanded$ | async">Class</span>
                </mat-expansion-panel-header>
                <ul>
                    <li>
                        <a [routerLink]="['/class/view']" (click)="toggleSidenav(false)">
                            <i class="fa fa-search" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">View classes</span>
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/class/add']" (click)="toggleSidenav(false)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">Create class</span>
                        </a>
                    </li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header matTooltip="Quest" matTooltipPosition="right" (click)="toggleSidenav(true)">
                    <i aria-hidden="true"><svg style="width:15px" aria-hidden="true" focusable="false" data-prefix="fas"
                            data-icon="scroll-old" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"
                            class="svg-inline--fa fa-scroll-old fa-w-20 fa-7x">
                            <path fill="currentColor"
                                d="M48 0C21.53 0 0 21.53 0 48v64c0 8.84 7.16 16 16 16h80V48C96 21.53 74.47 0 48 0zm208 412.57V352h288v-57.37c0-4.24-1.69-8.31-4.69-11.31L512 256l27.31-27.31c3-3 4.69-7.07 4.69-11.31v-50.75c0-4.24-1.69-8.31-4.69-11.31L512 128l26.86-26.86c3.27-3.27 5.21-7.84 4.86-12.45C539.98 39.15 498.48 0 448 0H111.59C121.74 13.41 128 29.92 128 48v137.37c0 4.24 1.69 8.31 4.69 11.31L160 224l-27.31 27.31c-3 3-4.69 7.07-4.69 11.31V416c0 38.87 34.65 69.65 74.75 63.12C234.22 474 256 444.46 256 412.57zm187.31-23.88L416 416l-27.31-27.31c-3-3-7.07-4.69-11.31-4.69H288v32c0 52.93-43.06 96-96 96h336c61.86 0 112-50.14 112-112 0-8.84-7.16-16-16-16H454.63c-4.25 0-8.32 1.69-11.32 4.69z"
                                class=""></path>
                        </svg></i>
                    <span *ngIf="expanded$ | async">Quest</span>
                </mat-expansion-panel-header>
                <ul>
                    <li>
                        <a [routerLink]="['/quests/view']" (click)="toggleSidenav(false)">
                            <i class="fa fa-search" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">View Quests</span>
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/quests/add']" (click)="toggleSidenav(false)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">Create Quest</span>
                        </a>
                    </li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header matTooltip="Help files" matTooltipPosition="right"
                    (click)="toggleSidenav(true)">
                    <i style="width:15px" class="fa fa-question" aria-hidden="true"></i>
                    <span *ngIf="expanded$ | async">Help Files</span>
                </mat-expansion-panel-header>
                <ul>
                    <li>
                        <a [routerLink]="['/help']" (click)="toggleSidenav(false)">
                            <i class="fa fa-search" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">View Help Files</span>
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/help/add']" (click)="toggleSidenav(false)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">Create Help File</span>
                        </a>
                    </li>
                </ul>
            </mat-expansion-panel>
            <!-- <mat-expansion-panel>
                <mat-expansion-panel-header matTooltip="Socials" matTooltipPosition="right"
                    (click)="toggleSidenav(true)">
                    <i class="fa fa-comment" aria-hidden="true"></i>
                    <span *ngIf="expanded$ | async">Socials</span>
                </mat-expansion-panel-header>
                <ul>
                    <li>
                        <a [routerLink]="['/socials']" (click)="toggleSidenav(false)">
                            <i class="fa fa-search" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">View socials</span>
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/socials/add']" (click)="toggleSidenav(false)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            <span *ngIf="expanded$ | async">Create socials</span>
                        </a>
                    </li>
                </ul>
            </mat-expansion-panel> -->
        </mat-accordion>

        <div class="sideNav__footer">

            <a href="javascript:void(0)" class="sideNav__footer-item sideNav__footer-item--link" (click)="logout()"
                matTooltip="Log out" matTooltipPosition="right">
                <i class="fa fa-sign-out" aria-hidden="true"></i>
                <span *ngIf="expanded$ | async">Log out</span>
            </a>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <div class="dark-overlay" (click)="toggleSidenav(false)"></div>
        <ng-content></ng-content>
    </mat-sidenav-content>

</mat-sidenav-container>